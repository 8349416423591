import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';

import{ Logger } from 'aws-amplify';
const logger = new Logger('Table-log');

const Table = (props) => {
    let history = useHistory();

    const handler = () => {
        let table;

        if ( !props.data ) {
            table = <h2>Loading...</h2>     
        } else {
            const columns = [
                { dataField: 'name', text: '施設名' },
                { dataField: 'office_no', text: '施設番号'},
                { dataField: 'admin_name', text: '施設管理者名' },
                { dataField: 'admin_email', text: '施設管理者email' },
                { dataField: 'service_enabled', text: 'サービス稼働中' },
                { dataField: 'customer_id', text: '顧客コード' },
                //{ dataField: 'plan_id', text: '契約タイプ' },
                { dataField: 'plan_name', text: '契約タイプ' },
            ];
            const rowEvents = {
                onClick: (e, row, rowIndex) => {
                    //console.log('clicked is: ', row);
                    logger.info('clicked is: ', row);
                    history.push(`/offices/${row.office_no}`);
                }
            };
            table = <BootstrapTable
                                keyField='office_no'
                                data={ props.data }
                                columns={ columns }
                                rowEvents={ rowEvents }
                                classes='home-table'
                                bordered={ true }
                                hover
                                condensed
                                />;
        }
        return table
    };

    return (
        <Fragment>
            { handler() }
        </Fragment>
    )
};

export default Table;