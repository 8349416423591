import { Modal, Button } from 'react-bootstrap';

const CustomModal = (props) => {

    return (
        <Modal
            show={ props.show }>
                <Modal.Header>
                    { props.header }
                </Modal.Header>

                <Modal.Body>
                    { props.body }
                </Modal.Body>

                <Modal.Footer>
                    { props.footer }
                    <Button
                        onClick={ () => {props.setShow(false)} }>
                            キャンセル
                    </Button>
                </Modal.Footer>
        </Modal>
    )
};

export default CustomModal;