import * as serviceCodeConf from 'confs/serviceCode_conf.json';

const isTokuyou = ( serviceCodeInput ) => {

    let rtn = true;


    const found = serviceCodeConf.services.find( obj => obj.label === serviceCodeInput )
    if ( found.isTokuyou === false ) {
        rtn = false;
    }


    return rtn
};

export default isTokuyou;