import axios from 'axios';

import getHeaderToken from 'functions/getHeaderToken';

import{ Logger } from 'aws-amplify';
const logger = new Logger('Office-log');

const deleteOfficeCollaboration = async (url, srcOfficeNumber, dstOfficeNumber, collaboratedOffices, setCollaboratedOffices) => {

    const deleteOfficeCollaborationParams = {
        src_office_number: srcOfficeNumber,
        dst_office_number: dstOfficeNumber
    };
    const header = await getHeaderToken();
    const config = Object.assign(header, {data : deleteOfficeCollaborationParams});
    const error_message = '連携施設の削除に失敗しました。\n\nステータスコード：'

    axios
        .delete(url, config)
        .then((res) => {
            if (res.status === 204) {
                let updatedCollaboratedOffices = collaboratedOffices.concat();
                updatedCollaboratedOffices.splice(collaboratedOffices.indexOf(dstOfficeNumber), 1);
                setCollaboratedOffices(updatedCollaboratedOffices);
            } else {
                logger.error(res);
                window.alert(error_message + res.status);
            }
        })
        .catch((e) => {
            logger.error(e);
            window.alert(error_message + e.response.status);
        });

};

export default deleteOfficeCollaboration;