const findOfficeNameFromOfficeNumber = (officeNumber, officeNames, currentOfficeNumber="", collaboratedOffices=[]) => {

    let result = "";

    if (currentOfficeNumber && (officeNumber === currentOfficeNumber)) {
        result = "自身の施設は登録できません。"
    } else if (collaboratedOffices && collaboratedOffices.includes(officeNumber)) {
        result = "連携施設に登録済みの施設です。"
    } else if (officeNumber in officeNames) {
        result = officeNames[officeNumber];
    } else if (officeNumber) {
        result = "施設が見つかりません。"
    }

    return result;
};

export default findOfficeNameFromOfficeNumber;