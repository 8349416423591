import * as serviceCodeConf from 'confs/serviceCode_conf.json';
import { Fragment } from 'react';

const createServiceCodeSelect = () => {

    let rtn = [<option></option>]

    serviceCodeConf.services.forEach((obj) => {
        rtn.push( <option>{obj.label}</option> );
    })

    return (
        <Fragment>{ rtn }</Fragment>
    );
};

export default createServiceCodeSelect;