import { Route } from 'react-router-dom';
import { Fragment } from 'react';

import Offices from 'pages/Offices';
import Office from 'pages/Office';
import Users from 'pages/Users';
import UserList from 'pages/UserList';

const Body = () => {
    return (
        <Fragment>

            <Route path="/" exact>
                <h2>Top page!</h2></Route>

            <Route path="/offices" exact>
                <Offices /></Route>

            <Route path="/offices/:office_no">
                <Office /></Route>

            <Route path="/users" exact>
                <Users /></Route>

            <Route path="/users/list" exact>
                <UserList /></Route>

        </Fragment>
    )
};

export default Body;