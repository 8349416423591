import { useState, Fragment } from "react";
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import createListData from 'functions/createUserListData';
import getHeaderToken from 'functions/getHeaderToken';
import useGetter  from "hooks/use-getter";
import useInput from 'hooks/use-input';

import List from 'components/UI/List';
import CustomButton from 'components/UI/Button';
import CustomModal from 'components/UI/Modal';

import{ Logger } from 'aws-amplify';
const logger = new Logger('Users-log');


const Users = () => {
    const history = useHistory();
    const endpoint = process.env.REACT_APP_USERS;
    const initialState = { username: '', email: ''};
    const res = useGetter(endpoint, initialState);
    const listData = createListData(res);

    const { 
        value: enteredName,
        //setValue: setEnteredName,
        isValid: isValidName,
        changeHandler: nameChangeHandler 
        } = useInput(value => 
            String(value.trim()) !== ''
            && String(value.trim()).length <= 128
            && /^([a-zA-Z0-9])+$/.test(String(value.trim()))
            );

    const { 
        value: enteredEmail,
        isValid: isValidEmail,
        //setValue: setEnteredEmail,
        changeHandler: emailChangeHandler 
        } = useInput(value =>
            /^([a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]{1,64})+$/.test(value)
            );

    const { 
        value: enteredWord,
        //setValue: setEnteredWord,
        isValid: isValidWord,
        changeHandler: wordChangeHandler 
        } = useInput( value => 
            String(value.trim()).length >= 13
            && String(value.trim()).length < 32
            // at least one digit
            &&/^(?=.*?\d)/.test(value)
            // at least one letter
            && /^(?=.*?[a-zA-Z])/.test(value)
            // at least one symbol
            && /^(?=.*?[!-/:-@¥[-`{-~])/.test(value)
            // no space allowed
            && /^([a-zA-Z0-9!-/:-@¥[-`{-~])+$/.test(value)
        );


    const [ isModal, setIsModal ] = useState(false);

    let isReadyToSubmit = false;
    if ( isValidName && isValidEmail && isValidWord ) { isReadyToSubmit = true };

    const submitHandler = async(event) => {
        event.preventDefault();
        //console.log('submission with: ', enteredName + ', ', enteredEmail);
        logger.info('submission with: ', enteredName + ', ', enteredEmail);
        
        let body = {
                username: enteredName,
                email: enteredEmail,
                id_token: enteredWord
        }
        const url = process.env.REACT_APP_USERS;
        const header = await getHeaderToken();
        axios
            .post(url, body, header)
            .then((res) => {
                switch (res.status) {
                    case 204:
                        logger.info('POST request was success: ', res);
                        //console.log('Success');
                        setIsModal(false);
                        break
                    default:
                        logger.error('POST request failed: ', res);
                        //console.log('Failed. The status is not 204: ', res);
                        break 
                }
            })
            .catch((e) => {
                //console.log(e)
                logger.error(e);
            });
    };

    const deleteHandler = async () => {
        let res = window.confirm('本当に退会しますか？OKを押すと即座にアカウントは削除され、ログアウトします');
        if ( res === true ) {
            const url = process.env.REACT_APP_USERS;
            const header = await getHeaderToken();
            axios
                .delete(url, header)
                .then((res) => {
                    switch (res.status) {
                        case 204:
                            logger.info('Successfully deleted user');
                            //console.log('Successfully deleted user');
                            async function signOut() {
                                try {
                                    await Auth.signOut();
                                } catch (e) {
                                    logger.error(e);
                                    //console.log('error signing out: ', error);
                                }
                            }
                            signOut();
                            break
                        default:
                            logger.error('Failed. The status is not 204: ', res);
                            //console.log('Failed. The status is not 204: ', res);
                            break 
                    }                    
                })
                .catch((e) => {
                    //console.log(e)
                    logger.error(e);
                });
        }
    };

    const routeChangeHandler = () => {
        const path = "/users/list";
        history.push(path);
    };

    const modalBody = (
        <Fragment>
            <Form onSubmit={submitHandler}>
                <Form.Group>
                    <Form.Label>追加するユーザーの名前</Form.Label>
                        {isValidName ? " ( OK! )" : " ( アルファベットか数字で入力 )"}
                            <Form.Control onChange={nameChangeHandler}></Form.Control>
                    <Form.Label>追加するユーザーのEmail</Form.Label>
                        {isValidEmail ? " ( OK! )" : " ( 有効なEmailアドレスを入力してください )"}
                            <Form.Control type="email" onChange={emailChangeHandler}></Form.Control> 
                    <Form.Label>仮パスワード</Form.Label>
                        {isValidWord ? " ( OK! )" : " ( 英数字記号各一つ以上含む13文字以上32文字未満 )"}
                            <Form.Control onChange={wordChangeHandler} data-testid='password-form'></Form.Control> 
                    <CustomButton 
                        type="submit" 
                        disabled={!isReadyToSubmit}
                        label="この情報でユーザー追加"
                        />           
                </Form.Group>
            </Form>
        </Fragment>
    );

    return(
        <Fragment>
            <List data={ listData }/>
            <br/>
            <CustomButton
                label="KM管理ユーザーの一覧を見る"
                onClick={ () => routeChangeHandler() }
                />
            <br/><br/>
            <CustomButton 
                label="サービスを退会する" 
                onClick={() => deleteHandler()}
                testid='userDelete-modal-button'/>
            <br/><br/>
            <CustomButton 
                onClick={() => setIsModal(true)}
                label="新しいKM管理ユーザーを追加する" 
                testid='userAdd-modal-button'/>
            <CustomModal 
                show={ isModal }
                setShow={ () => {setIsModal()} }
                header={ "KM管理ユーザーを新規に追加する" }
                body={ modalBody }/>
        </Fragment>
    )
};

export default Users; 