import{ Auth, Logger } from 'aws-amplify';
const logger = new Logger('getToken-log');

const getToken = async () => {
    let accessToken;
    try {
        const user = await Auth.currentAuthenticatedUser();
        //console.log('Successfully obtained token', user);
        logger.info('Successfully obtained token', user);
        accessToken = user.signInUserSession.accessToken.jwtToken;
        //const idt = user.signInUserSession.idToken;
        //const idtJwt = idt.jwtToken;
        //const payload = idt.payload;
        //console.log('jwt is: ', idtJwt);
    } catch (e) {
        //console.log('Getting token failed with error of: ', e);
        logger.error(e);
    }
    return accessToken
};

export default getToken;