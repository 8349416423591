import * as permissionConf from 'confs/permission_conf.json';
import{ Logger } from 'aws-amplify';
const logger = new Logger('createPermissionListData-log');

const createPermissionListData = (input) => {

    let out = {
        shindan: [],
        unyou: [],
        shift: [],
        shift_auto: [],
        bizDiag: [],
    };

    // Input validation
    const arr = input.data
    if ( !arr || arr.length === 0 ) { return out; }

    // Config data
    const conf = permissionConf.default

    // Creating data
    arr.forEach((obj) => {
        let app = {};
        let func = {};
        let name = "";
        let status = "";
        let d = {
                "firstCol": "",
                "secondCol": "",                
            };

        if ( !conf[obj.app_id] ) {
            logger.warn(`${ obj.app_id } was passed but no equivalent id in conf.`);
            return;
        }
        app = conf[obj.app_id];

        try {
            func = app[obj.function_id];
            name = func.name;
        } catch (e) {
            // Escape for option permissions
            switch ( obj.function_id ) {
                case 'externallink':
                    break;
                default:
                    logger.warn(`Object is not properly formatted. obj is: ${ JSON.stringify(obj) }`);
                    break;
            }
            return;
        }

        switch ( obj.status ) {
            case 0:
                status = "-";
                break;
            case 1:
                status = "Yes";
                break;
            default:
                logger.warn(`status is not 0 or 1 but: ${ obj.status }`);
                return;                    
        }
        d.firstCol = name;
        d.secondCol = status;

        switch ( obj.app_id ) {
            case "shift":
                out.shift.push(d);
                break;
            case "accountsettings":
                break;
            default:
                logger.warn(`app_id has unexpected value: ${ obj.app_id }`);
                return;
        }
    })

    return out;
};

export default createPermissionListData