import axios from 'axios';

import getHeaderToken from 'functions/getHeaderToken';

import{ Logger } from 'aws-amplify';
const logger = new Logger('Offices-log');

const postNewOffice = async ( body, url ) => {

    let rtn = {
        'status' : 500,
        'isAlert' : false,
        'isRefresh' : false,
        'msg' : '',
    };


    const header = await getHeaderToken();
    await axios
        .post( url, body, header)
        .then((res) => {
            if ( res.status === 204 ) {
                rtn.status = res.status;
                return rtn
            } else {
                rtn.status = res.status;
                return rtn               
            }
        })
        .catch((e) => {
            if (e.response.data) {
                rtn.isAlert = true;

                if ( e.response.data.emailAlreadyExists === true ) {
                    rtn.msg = 'Emailは既に登録されています';
                } else if ( e.response.data.officeNoAlreadyExists === true ) {
                    rtn.msg = '同じ施設が既に登録されています';
                } else if ( e.response.data.wrongFormatParameters === true ) {
                    rtn.msg = '入力値に問題があり、登録できませんでした';
                } else if ( e.response.data.creationOkPermissionFail === true ) {
                    rtn.isRefresh = true;
                    rtn.msg = '施設登録成功。ただ権限変更失敗。権限は手動で修正してください';
                } else {
                    rtn.msg = '問題が発生し、登録できませんでした';
                }
                
            } else {
                logger.error(e);
            }

            return rtn
        });


    return rtn
}

export default postNewOffice;