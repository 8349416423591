import { ListGroup } from "react-bootstrap";
import { Fragment } from 'react';

const List = (props) => {
    const handler = () => {
        let list;

        if ( props.data === '' ) {
            list = <h2>loading</h2>
        } else if ( typeof props.data === "undefined" ) {
            list = <h2>loading</h2>
        } else {
            const listGroup = props.data.map((obj, idx) => (
                <ListGroup 
                    horizontal 
                    key={ idx }
                    role="tablist">
                        <ListGroup.Item variant="success">{ obj.firstCol }</ListGroup.Item>
                        <ListGroup.Item>{ obj.secondCol }</ListGroup.Item>
                </ListGroup>
            ));

            list = (
                    <Fragment>
                        { listGroup }
                    </Fragment>
            )
        }
        return list
    };

    return (
        <Fragment>
            { handler() }
        </Fragment>
    )
};

export default List;