import { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

const UserTable = (props) => {

    const handler = () => {
        let table;

        if ( !props || !props.data ) { 
            table = <h2>Loading...</h2> 
        } else {
            const columns = [
                { dataField: 'username', text: '登録ユーザネーム' },
                { dataField: 'email', text: '登録Email' },
            ];
            table = 
                <BootstrapTable
                    keyField='id'
                    data={ props.data }
                    columns={ columns }
                    classes='home-table'
                    bordered={ true }
                    hover
                    condensed
                />;
        }
        return table
    };


    return (
        <Fragment>
            { handler() }
        </Fragment>
    )
};

export default UserTable;