import axios from 'axios';

import getHeaderToken from 'functions/getHeaderToken';

import{ Logger } from 'aws-amplify';
const logger = new Logger('Office-log');

const postOfficeCollaboration = async (url, srcOfficeNumber, dstOfficeNumber, setEnteredCollaborateOfficeNumber, collaboratedOffices, setCollaboratedOffices) => {

    const postOfficeCollaborationData = {
        src_office_number: srcOfficeNumber,
        dst_office_number: dstOfficeNumber
    };
    const config = await getHeaderToken();
    const error_message = '連携施設の追加に失敗しました。\n\nステータスコード：'

    axios
        .post(url, postOfficeCollaborationData, config)
        .then((res) => {
            if (res.status === 201) {
                let updatedCollaboratedOffices = collaboratedOffices.concat();
                updatedCollaboratedOffices.push(dstOfficeNumber);
                setCollaboratedOffices(updatedCollaboratedOffices);
                setEnteredCollaborateOfficeNumber(''); // 施設番号のフォームの入力状態をリセット
            } else {
                logger.error(res);
                window.alert(error_message + res.status);
            }
        })
        .catch((e) => {
            logger.error(e);
            window.alert(error_message + e.response.status);
        });

};

export default postOfficeCollaboration;