import useGetter  from "hooks/use-getter";

import UserTable from "components/UI/UserTable";

const UserList = () => {
    const url = process.env.REACT_APP_USERS + '/list';
    let tableData = '';
    tableData = useGetter(url);

    if ( !tableData || !tableData.users ) { 
        return <h2>Loading...</h2> 
    } else { 
        return <UserTable data={ tableData.users }/> 
    }
};

export default UserList;