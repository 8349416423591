import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';

import styles from 'App.module.scss';
import Body from 'components/Layout/Body';
import Header from 'components/Layout/Header';
import SideNav from 'components/Layout/SideNav';

import { Amplify, Logger } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);


// Global log level settings
const logger = new Logger("App-log");
if ( process.env.REACT_APP_DEBUG ) {

    if ( process.env.REACT_APP_DEBUG === 'True' ){
        window.LOG_LEVEL = "INFO";
        logger.info('Application is running on DEBUG mode');
        
    } else if ( process.env.REACT_APP_DEBUG === 'False' ) {
        window.LOG_LEVEL = "ERROR";

    } else {
        logger.warn('REACT_APP_DEBUG is not right value. Value has to be True or False');
    }

} else {
    logger.warn('REACT_APP_DEBUG does not exist on env');
}


const App = () => {
    return (
        <AmplifyAuthenticator >
            <AmplifySignIn 
                usernameAlias="email" 
                slot="sign-in" 
                hideSignUp />

            <div className={ styles.app }>
                <div className={ styles.app__body }>
                    <Body />
                </div>
                <div className={ styles.app__header }>
                    <Header />
                </div>
                <div className={ styles.app__sidebar }>
                    <SideNav />
                    <AmplifySignOut />
                </div>
            </div>

        </AmplifyAuthenticator>
    );
}

export default App;
