import { Fragment } from 'react';


const Header = () => {
    return (
        <Fragment>
            <h2
                style={{
                    color: "white",
                }}
                >KM管理UI</h2>
        </Fragment>
    )
};

export default Header;