import{ Logger } from 'aws-amplify';
const logger = new Logger('createUserListData-log');


const createListData = (input) => {

    if ( !input ) { return ({ email: '', username: ''}) };

    try {
        const email = input.email;
        const name = input.username;

        return (
            [ 
                {
                    "firstCol" : "ログインユーザ名",
                    "secondCol": name,
                }, {
                    "firstCol" : "ログインユーザEmail",
                    "secondCol": email,
                }
            ]
        )
    } catch (e) {
        logger.error(e);
        return ({ email: '', username: ''})
    }
};

export default createListData;