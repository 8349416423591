import{ Logger } from 'aws-amplify';
const logger = new Logger('checkPermissionStatus-log');

const checkPermissionStatus = (perms, app_id, function_id) => {
    
    let out = null;

    // Input validation
    let arr;
    try {
        arr = perms.data; 
    } catch (e) {
        logger.error(e);
        return out;
    }
    if ( !arr || arr.length === 0 ) { return out; }
    if ( !app_id || typeof app_id != 'string' ) { return out; }
    if ( !function_id || typeof function_id != 'string' ) { return out; }

    // Check permission status
    arr.forEach((obj) => {
        let app = obj.app_id;
        let func = obj.function_id;
        let status = obj.status;

        if ( app === app_id && func === function_id ) {
            switch (status) {
                case 1:
                    out = 1;
                    break
                case 0:
                    out = 0;
                    break
                default:
                    logger.error('permission status must be 0 or 1');
                    break
            }
        }
    })
    
    //if ( out === null ) { logger.warn(`The permission for ${ app_id } and ${ function_id } is null`) }
    return out
}

export default checkPermissionStatus