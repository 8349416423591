import { Button } from 'react-bootstrap';

const CustomButton = (props) => {
    const handler = () => {
        return props.label
    };

    return (
        <Button
            variant="warning"
            size="lg"
            onClick={props.onClick}
            type={props.type}
            disabled={props.disabled}
            data-testid={props.testid}
            as={props.as}
            to={props.to}
            >{ handler() }   
        </Button>
    )
};

export default CustomButton;