import getToken from 'functions/getToken';

const getHeaderToken = async () => {
    const accessToken = await getToken();
    const header = {
        headers: {
            Authorization: 'Bearer ' + accessToken,
        },
    };
    return header
};

export default getHeaderToken;