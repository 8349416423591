import { Fragment } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const SideNav = () => {
    return (
        <Fragment>
            <Nav 
                //variant="pills"
                className="flex-column"
                defaultActiveKey="/">
                    <Nav.Item>
                        <Nav.Link as={Link} to="/">HOME</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/offices">利用施設</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/users">ユーザー情報</Nav.Link>
                    </Nav.Item>
            </Nav>
        </Fragment>
    )
};

export default SideNav;