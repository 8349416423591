import { useState } from 'react';

const useInput = ( validationHandler ) => {
    const [ entered, setEntered ] = useState('');

    const changeHandler = (event) => {
        setEntered(event.target.value);
    };

    const result = validationHandler(entered);

    return { 
        value: entered,
        setValue: setEntered,
        isValid: result,
        changeHandler
     }

};

export default useInput;