import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';

import useGetter from 'hooks/use-getter';
import useInput from 'hooks/use-input';
import getHeaderToken from 'functions/getHeaderToken';
import createServiceCodeSelect from 'functions/createServiceCodeSelect';
import isTokuyou from 'functions/isTokuyou';
import postNewOffice from 'functions/postNewOffice';

import { Button, Form } from 'react-bootstrap';
import Table from 'components/UI/Table';
import CustomModal from 'components/UI/Modal';

import{ Logger } from 'aws-amplify';
const logger = new Logger('Offices-log');


const Offices = () => {

    // Endpoints
    const officesUrl = process.env.REACT_APP_OFFICES;
    const urlPlans = process.env.REACT_APP_PLANS;

    let tableData = '';
    tableData = useGetter(officesUrl);

    let resPlans = { plans: [] };
    resPlans = useGetter(urlPlans, resPlans);
    const selections = resPlans.plans.map((obj) => <option key={obj.id}>{obj.name}</option>);

    const { 
        value: enteredMainOfficeNum,
        setValue: setEnteredMainOfficeNum,
        isValid: isValidMainOfficeNum,
        changeHandler: mainOfficeNumChangeHandler 
        } = useInput( value => 
            /^([0-9a-zA-Z]{10})$/.test(value)
        );
    const { 
        value: enteredServiceCodeNum,
        setValue: setEntereServiceCodeNum,
        isValid: isValidServiceCodeNum,
        changeHandler: serviceCodeNumChangeHandler 
        } = useInput( value =>
            String(value.slice(0, 3)).length === 3
        );
    const { 
        value: enteredCorpIdNum,
        setValue: setEntereCorpIdNum,
        isValid: isValidCorpIdNum,
        changeHandler: corpIdNumChangeHandler 
        } = useInput( value =>
            /^([0-9]{13})$/.test(value)
        );
    const { 
        value: enteredAdminFirstName,
        setValue: setEnteredAdminFirstName,
        isValid: isValidAdminFirstName,
        changeHandler: adminFirstNameChangeHandler 
        } = useInput( value => 
            String(value.trim()).length !== 0
            && String(value.trim()).length <= 8
        );
    const { 
        value: enteredAdminFamilyName,
        setValue: setEnteredAdminFamilyName,
        isValid: isValidAdminFamilyName,
        changeHandler: adminFamilyNameChangeHandler 
        } = useInput( value => 
            String(value.trim()).length !== 0
            && String(value.trim()).length <= 8
        );
    const { 
        value: enteredAdminEmail,
        setValue: setEnteredAdminEmail,
        isValid: isValidAdminEmail,
        changeHandler: adminEmailChangeHandler 
        } = useInput( value => 
            /^([a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]{1,64})+$/.test(value)
        );
    const { 
        value: enteredUserName,
        setValue: setEnteredUserName,
        isValid: isValidUserName,
        changeHandler: userNameChangeHandler 
        } = useInput( value => 
            /^([a-zA-Z0-9]{1,2048})+$/.test(value)
        );
    const { 
        value: enteredWord,
        setValue: setEnteredWord,
        isValid: isValidWord,
        changeHandler: wordChangeHandler 
        } = useInput( value => 
            String(value.trim()).length >= 13
            && String(value.trim()).length < 32
            // at least one digit
            &&/^(?=.*?\d)/.test(value)
            // at least one letter
            && /^(?=.*?[a-zA-Z])/.test(value)
            // at least one symbol
            && /^(?=.*?[!-/:-@¥[-`{-~])/.test(value)
            // no space allowed
            && /^([a-zA-Z0-9!-/:-@¥[-`{-~])+$/.test(value)
        );
    const { 
        value: enteredPlan,
        isValid: isValidPlan,
        setValue: setEnteredPlan,
        changeHandler: planChangeHandler 
        } = useInput(value =>
            String(value.trim()) !== ''
            );
    const { 
        value: enteredMaxUserNum,
        isValid: isValidMaxUserNum,
        setValue: setEnteredMaxUserNum,
        changeHandler: maxUserNumChangeHandler 
        } = useInput(value =>
            value !== ''
            && value > 0
            && value <= 100
            && /^[0-9]+$/.test(value)
            );
    const { 
        value: enteredMaxStorage,
        isValid: isValidMaxStorage,
        setValue: setEnteredMaxStorage,
        changeHandler: maxStorageChangeHandler 
        } = useInput(value =>
            value !== ''
            && value > 0
            && value <= 100
            && /^[0-9]+$/.test(value)
            );
    const { 
        value: enteredStartDate,
        isValid: isValidStartDate,
        setValue: setEnteredStartDate,
        changeHandler: startDateChangeHandler 
        } = useInput(value =>
            String(value.trim()) !== ''
            );
    const { 
        value: enteredCustomerId,
        setValue: setEnteredCustomerId,
        isValid: isValidCustomerId,
        changeHandler: customerIdChangeHandler 
        } = useInput( value =>
            String(value.trim()).length === 10
            // no space allowed
            && /^([0-9])+$/.test(value)
        );
    const { 
        value: enteredPaymentIntervalMonth,
        setValue: setEnteredPaymentIntervalMonth,
        isValid: isValidPaymentIntervalMonth,
        changeHandler: paymentIntervalMonthChangeHandler 
        } = useInput( value => 
            String(value.trim()) !== ''
        );

    let isReadyToSubmit = false;
    if (isValidMainOfficeNum
            && isValidServiceCodeNum
            && isValidCorpIdNum
            && isValidAdminFirstName
            && isValidAdminFamilyName
            && isValidAdminEmail
            && isValidUserName
            && isValidWord
            && isValidMaxUserNum
            && isValidMaxStorage 
            && isValidPlan 
            && isValidStartDate
            && isValidPaymentIntervalMonth
            && isValidCustomerId ) { isReadyToSubmit = true };


    // States
    const [ isModal, setIsModal ] = useState(false);
    const [ isSearched, setIsSearched ] = useState(false);
    const [ searchedTableData, setSearchedTableData ] = useState([]);

    useEffect(() => {
        setEnteredMainOfficeNum('');
        setEntereServiceCodeNum('');
        setEntereCorpIdNum('');
        setEnteredAdminFirstName('');
        setEnteredAdminFamilyName('');
        setEnteredAdminEmail('');
        setEnteredUserName('');
        setEnteredWord('');
        setEnteredPlan('');
        setEnteredMaxUserNum('');
        setEnteredMaxStorage('');
        setEnteredStartDate('');
        setEnteredPaymentIntervalMonth('');
        setEnteredCustomerId('');
    }, [
        isModal, 
        setEnteredMainOfficeNum,
        setEntereServiceCodeNum,
        setEntereCorpIdNum,
        setEnteredAdminFirstName,
        setEnteredAdminFamilyName,
        setEnteredAdminEmail, 
        setEnteredUserName,
        setEnteredWord, 
        setEnteredPlan,
        setEnteredMaxUserNum,
        setEnteredMaxStorage,
        setEnteredStartDate,
        setEnteredPaymentIntervalMonth,
        setEnteredCustomerId
    ]);

    const paymentIntervalHandler = (paymentIntervalString) => {

        let value = null;
        switch ( paymentIntervalString ) {
            case "月額":
                value = 1
                break
            case "年額":
                value = 12
                break
            default:
                //console.log("wrong payment interval value")
                logger.warn('Wrong payment interval value was input');
        }
        return value
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        const selectedPlan = resPlans.plans.filter(obj => {return obj.name === enteredPlan});
        const paymentIntervalInt = paymentIntervalHandler(enteredPaymentIntervalMonth);

        // Tmp: setting every service_code other than Tokuyou to standard-2
        let plan_id;
        if ( isTokuyou(enteredServiceCodeNum) === true ) {
            plan_id = selectedPlan[0].id;
        } else {
            plan_id = 4;
        }        

        let body = {
            office_no: enteredMainOfficeNum,
            admin_first_name: enteredAdminFirstName,
            admin_last_name: enteredAdminFamilyName,
            admin_email: enteredAdminEmail,
            username: enteredUserName,
            id_token: enteredWord,
            customer_id: enteredCustomerId,
            plan_id: plan_id,
            start_date: enteredStartDate,
            end_date: null,
            max_user_num: Number(enteredMaxUserNum),
            max_strage_size: Number(enteredMaxStorage),
            mfp_enabled: false,
            payment_interval_month: paymentIntervalInt,
            corporation_no: enteredCorpIdNum,
            service_code: enteredServiceCodeNum.slice(0, 3),
        }
        logger.info('body is: ', body);

        const out = await postNewOffice( body, officesUrl );

        if ( out.status === 204 ) {
            logger.info('POST request was success!');
            refreshHandler();
            return         
        } 

        if ( out.isAlert === true ) {
            if ( out.isRefresh === true) {
                alert( out.msg );
                refreshHandler();
            } else {
                alert( out.msg );
            }
        }

        return
    };

    const searchHandler = async (event) => {
        event.preventDefault();
        const url = process.env.REACT_APP_OFFICES + `/?customer_id=${enteredCustomerId}`;
        const header = await getHeaderToken();
        axios
            .get(url, header)
            .then((res) => {
                if ( res.data.offices.length === 0 ) {
                    alert('該当するデータが見つかりませんでした。');
                } else {
                    setIsSearched(true);
                    setSearchedTableData(res.data.offices);  
                }
            })
            .catch((e) => {
                //console.log(e)
                logger.error(e);
            });
    };

    const refreshHandler = () => {
        window.location.reload(false);
    };

    
    const tablePlanNameInserter = (table) => {
        let plan;
        for ( const row of table ){
            const id = row.plan_id;
            plan = resPlans.plans.find(obj => obj.id === id);
            if ( plan ) { row.plan_name = plan.name; }
        }
    };
    if ( tableData && resPlans ){ tablePlanNameInserter(tableData); }
    if ( searchedTableData[0] && resPlans ){ tablePlanNameInserter(searchedTableData); }


    const modalBody = (
        <Fragment>
            <Form onSubmit={submitHandler}>
                <Form.Group>
                    <Form.Label>施設番号</Form.Label>
                        { isValidMainOfficeNum ? "( OK )" : "( 10桁の英数字で入力 )" }
                        <Form.Control onChange={mainOfficeNumChangeHandler}/>
                    <Form.Label>サービスコード</Form.Label>
                        { isValidServiceCodeNum ? "( OK )" : "( 選択してください )" }
                        <Form.Control as="select" onChange={ serviceCodeNumChangeHandler }>{ createServiceCodeSelect() }</Form.Control>
                    <Form.Label>法人番号</Form.Label>
                        { isValidCorpIdNum ? "( OK )" : "( 13桁の数字で入力 )" }
                        <Form.Control onChange={ corpIdNumChangeHandler }></Form.Control>
                    <Form.Label>管理者姓</Form.Label>
                        {isValidAdminFamilyName ? " ( OK! )" : " ( 8文字以下で入力してください )"}
                        <Form.Control onChange={adminFamilyNameChangeHandler}></Form.Control>
                    <Form.Label>管理者名</Form.Label>
                        {isValidAdminFirstName ? " ( OK! )" : " ( 8文字以下で入力してください )"}
                        <Form.Control onChange={adminFirstNameChangeHandler}></Form.Control>
                    <Form.Label>ログインユーザ名</Form.Label>
                        {isValidUserName ? " ( OK! )" : " ( アルファベットか数字で入力してください )"}
                        <Form.Control onChange={userNameChangeHandler}></Form.Control>
                    <Form.Label>管理者email</Form.Label>
                        {isValidAdminEmail ? " ( OK! )" : " ( 有効なEmailアドレスを入力してください )"}
                        <Form.Control type="email" onChange={adminEmailChangeHandler}></Form.Control>
                    <Form.Label>パスワード</Form.Label>
                        {isValidWord ? " ( OK! )" : " ( 英数字記号各一つ以上含む13文字以上32文字未満 )"}
                        <Form.Control onChange={wordChangeHandler}></Form.Control>
                    <Form.Label>契約タイプ</Form.Label>
                        {isValidPlan ? " ( OK! )" : ` ( 契約タイプを選択してください *特養510以外は何を選択してもstandard2になります )`}
                        <Form.Control as="select" onChange={planChangeHandler}>
                            <option></option>
                            {selections}
                        </Form.Control>
                    <Form.Label>ユーザ数上限</Form.Label>
                        {isValidMaxUserNum ? " ( OK! )" : " ( 100以下の有効な整数を入力してください )"}
                        <Form.Control type="number" onChange={maxUserNumChangeHandler}/>  
                    <Form.Label>ストレージ上限</Form.Label>
                        {isValidMaxStorage ? " ( OK! )" : " ( 100以下の有効な整数を入力してください )"}
                        <Form.Control type="number" onChange={maxStorageChangeHandler}/>
                    <Form.Label>契約開始日</Form.Label>
                        {isValidStartDate ? " ( OK! )" : ` ( 日にちを選択してください )`}
                        <Form.Control type="date" onChange={startDateChangeHandler}/>
                    <Form.Label>支払いタイプ</Form.Label>
                        {isValidPaymentIntervalMonth ? " ( OK! )" : " ( 選択してください )"}
                        <Form.Control as="select" onChange={paymentIntervalMonthChangeHandler}>
                            <option></option>
                            <option>月額</option>
                            <option>年額</option>
                        </Form.Control>
                    <Form.Label>顧客コード</Form.Label>
                        {isValidCustomerId ? " ( OK! )" : " ( 10桁の数字を入力してください )"}
                        <Form.Control type="number" onChange={customerIdChangeHandler}/>
                </Form.Group>
                <Button type="submit" disabled={!isReadyToSubmit}>この情報で登録</Button>
            </Form>
        </Fragment>
    );

    return (
        <Fragment>
            <Button onClick={() => setIsModal(true)}>新規追加</Button>
            <br/><br/>
            <Form onSubmit={searchHandler}>
                <Form.Control type="number" placeholder="10桁の顧客コードで検索" onChange={customerIdChangeHandler}></Form.Control>
                <Button type="submit" disabled={!isValidCustomerId}>検索</Button>
            </Form>
            <br/>

            { isSearched ? <Button onClick={() => refreshHandler()}>一覧を再表示</Button> : <></>}
            { isSearched ? <Table data={ searchedTableData }/> : <Table data={ tableData }/> }
            
            <CustomModal 
                    show={ isModal }
                    setShow={ () => {setIsModal()} }
                    header={ "施設情報を新規に追加する" }
                    body={ modalBody }
                    />
        </Fragment>
    )
};

export default Offices;