import{ Logger } from 'aws-amplify';
const logger = new Logger('createOfficeListData-log');


const createData = (input, resPlans) => {

    if ( !input ) { return ({ dataName: '', dataChangeable: '', dataFixed: '' }) }

    try {
        const name = input.name;
        const officeNo = input.office_no;
        const username = input.admin_name;
        const email = input.admin_email;
        const kana = input.kana;
        const address = input.address;
        const tel = input.tel;
        const fax = input.fax;
        const customer_id = input.customer_id;
        const start_date = input.start_date;
        const end_date = input.end_date;
        const max_user_num = input.max_user_num;
        const max_strage_size = input.max_strage_size;
        const service_code = input.service_code;

        const plan_id = input.plan_id;
        let plan_name = '';
        if ( resPlans.plans.length !== 0 && plan_id ){
            const plan = resPlans.plans.find( obj => obj.id === plan_id );
            plan_name = plan.name;
        }

        const service_enabled = input.service_enabled;
        let serviceCondition = "停止中";
        if ( service_enabled === true ) { serviceCondition = "稼働中"};

        const mfp_enabled = input.mfp_enabled;
        let mfpCondition = "未連携";
        if ( mfp_enabled === true ) { mfpCondition = "連携中"};

        const payment_interval_month = input.payment_interval_month;
        let payment_interval = null;
        if ( payment_interval_month === 1 ) { payment_interval = "月額" }
        else if ( payment_interval_month === 12 ) { payment_interval = "年額" }

        return (
            {
                dataName: [ 
                    {
                        "firstCol" : "施設名",
                        "secondCol": name,
                    }, {
                        "firstCol" : "施設番号",
                        "secondCol": officeNo,
                    }, {
                        "firstCol" : "サービスコード",
                        "secondCol": service_code,
                    },
                ],
                dataChangeable: [ 
                    {
                        "firstCol" : "施設管理者名",
                        "secondCol": username,                       
                    }, {
                        "firstCol" : "施設管理者email",
                        "secondCol": email,
                    },{
                        "firstCol" : "ユーザ数上限",
                        "secondCol": max_user_num,
                    },{
                        "firstCol" : "ストレージ上限",
                        "secondCol": max_strage_size,                        
                    },{
                        "firstCol" : "サービス状況",
                        "secondCol": serviceCondition,                         
                    },{
                        "firstCol" : "契約タイプ",
                        "secondCol": plan_name,                        
                    },{
                        "firstCol" : "契約開始日",
                        "secondCol": start_date,                        
                    },{
                        "firstCol" : "契約終了日",
                        "secondCol": end_date,                        
                    }, {
                        "firstCol" : "顧客番号",
                        "secondCol": customer_id,                        
                    }, {
                        "firstCol" : "IWS連携",
                        "secondCol": mfpCondition,                        
                    }, {
                        "firstCol" : "支払いタイプ",
                        "secondCol": payment_interval,                        
                    }
                ],
                dataFixed: [ 
                    {
                        "firstCol" : "施設名(かな)",
                        "secondCol": kana,
                    }, {
                        "firstCol" : "住所",
                        "secondCol": address,                     
                    }, {
                        "firstCol" : "電話番号",
                        "secondCol": tel,
                    },{
                        "firstCol" : "FAX番号",
                        "secondCol": fax,
                    },
                ]
            }
        )
    } catch (e) {
        logger.error(e);
        return ({ dataName: '', dataChangeable: '', dataFixed: '' })
    }
};

export default createData;