import { useState, useEffect } from 'react';
import axios from 'axios';
import getHeaderToken from 'functions/getHeaderToken';

import{ Logger } from 'aws-amplify';
const logger = new Logger('useGetter-log');


const useGetter = (endpoint, initialState, queryParams={}) => {
    const [ data, setData ] = useState(initialState);

    useEffect(() => {
        const fetchData = async () => {
            const header = await getHeaderToken();
            const config = queryParams ? Object.assign(header, {params: queryParams}) : header;
            axios
                .get(endpoint, config)
                .then((res) => {
                    switch ( res.status ) {
                        case 200:
                            logger.info('Successfully fetched with status 200. Response is: ', res);

                            if ( !endpoint ) {
                                //For test. Since there is no endpoint on mocking axios
                                if ( typeof res.data.offices !== 'undefined' ) {
                                    setData(res.data.offices);
                                } else {
                                    setData(res.data);
                                }
                            } else {
                                if ( endpoint.slice(-7) === 'offices' ) {
                                    setData(res.data.offices);
                                } else {
                                    setData(res.data);
                                }
                            }
                            break
                        default:
                            logger.error('Fetch failed. The status was not 200: ', res);
                            break
                    }
                })
                .catch((e) => {
                    logger.error(e);
                });
        }
        fetchData();
    }, [endpoint]);

    return data
};

export default useGetter;